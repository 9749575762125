"use client"
import React, { useRef, useState } from 'react'
import Link from 'next/link';
import cookies from 'js-cookie';
import { useRouter } from 'next/navigation'
// import { useRouter } from 'next/router';
import { login } from '../utils/_actions';
import { studentLogin } from '../utils/app';
import { notify } from '../utils/helper';

const StudentLogin = () => {
  const [error, setError] = useState('');
  const formRef = useRef();
  const router = useRouter();
  // const [loading, setLoading] = useState(false);

  const handelsubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await studentLogin({
        matric_no: formRef.current.matric_no.value,
        password: formRef.current.password.value,
      });

      console.log(res.data.token)

      cookies.set('student', res.data.token);

      res.success && router.push('/student/dashboard')

    } catch (error) {
      console.log(error.response)
      if (error.response?.data?.errors) return notify('error', error.response.data.message)
      notify('error', error.message)
    } finally {
      console.log('done')
    }
  console.log(formRef.current);
  }

  return (
    <div className="w-full max-w-md px-4 pt-8 mx-auto">
      {error && <div className="text-red-500">{error}</div>}
      <form ref={formRef} className='bg-white w-full max-h-[25rem] pb-5' onSubmit={handelsubmit}>
        <div className='flex flex-col items-center'>
          <h3 className='text-3xl font-bold text-black'>Student Login</h3>
          <div className='w-full flex flex-col items-center pt-8'>
            <input type="text" name="matric_no" id="matric_no" className='w-3/4 outline-none border rounded-lg p-3' placeholder='Matric No' />
            <input type="password" name="password" id="password" className='w-3/4 outline-none border rounded-lg mt-5 p-3' placeholder='Password' />
            <button className='w-3/4 bg-orange-500 text-white font-semibold cursor-pointer rounded-lg mt-5 p-3'>Login</button>
          </div>
        </div>
        {/* <div className='w-full items-right pl-9 pt-3 text-orange-500'> */}
          {/* <a href="" className='block'>Forgot Password?</a> */}
          {/* <p className='text-xl'>😎 ←</p> */}
        {/* </div> */}
        <div className='w-full text-right pr-14 gap-2 mt-7'>
          <a href="#" className='text-orange-900 underline decoration-orange-500'>Forgot Password?</a>
        </div>
      </form>
    </div>
  );
}

export default StudentLogin;